import React, { useState } from 'react'
import { DatabaseService } from '../../context/DatabaseContext';
import { Spinner } from 'flowbite-react';
import { BsCheck } from 'react-icons/bs';

const CreateLocation = ({ closeModal, user, companyData }) => {

    const [branch, setBranch] = useState('');
    const [location, setLocation] = useState('');
    const [description, setDescription] = useState('');
    const [company, setCompany] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    const { createBranch } = DatabaseService();

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            branch: branch,
            location: location,
            description: description,
            company: company,
            parentCompany: companyData.entityId,
            createdBy: user,
            dateAdded: new Date()
        }

        try {
            setLoading(true);
            createBranch(data).then(() => {
                setLoading(false);
                setSuccess(true);
                setTimeout(() => {
                    closeModal();
                    setSuccess(false)
                }, 2000)
            })
        } catch (err) {
            setLoading(false);
            setError(err.message);
        }


    }

    return (
        <>
            {
                loading ? <div className=" w-full grid place-items-center py-8"><Spinner /></div>
                    :
                    <>
                        {
                            success ? <div className="w-full grid place-items-center py-8">
                                <BsCheck className='text-green-500 text-4xl' />
                                <p className="text-green-500">Location added successfully</p>
                            </div>
                                :
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="company">
                                            Company Name
                                        </label>
                                        <input onChange={(e) => setCompany(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="company" type="text" placeholder="Company" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="branch">
                                            Branch
                                        </label>
                                        <input onChange={(e) => setBranch(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="branch" type="text" placeholder="Branch" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                                            Description
                                        </label>
                                        <input onChange={(e) => setDescription(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="description" type="text" placeholder="Description" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="location">
                                            Location of branch
                                        </label>
                                        <input onChange={(e) => setLocation(e.target.value)} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="location" type="text" placeholder="Location" />
                                    </div>

                                    {error && <h3 className='mb-4 text-center text-red-600 font-semibold'>{error}</h3>}
                                    <div className="mb-4">
                                        <button className='bg-black text-white w-full py-3 rounded-md' type='submit'>SUBMIT</button>
                                    </div>
                                </form>
                        }
                    </>

            }

        </>
    )
}

export default CreateLocation