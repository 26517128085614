import React from 'react'
import { useAuth } from '../../context/AuthContext';
import { Navigate } from 'react-router-dom';
import Recover from '../../components/auth/Recover';


const RecoverPage = () => {

  // CHECK AUTH STATUS FROM AUTH CONTEXT
  const { currentUser } = useAuth();

  // IF USER IS LOGGED IN, REDIRECT TO DASHBOARD ELSE TO SIGN UP PAGE

  return currentUser ? <Navigate to="/" /> : <Recover />
}

export default RecoverPage