import React from 'react'
import SignIn from '../../components/auth/SignIn'
import { useAuth } from '../../context/AuthContext';
import { Navigate } from 'react-router-dom';

const SignInPage = () => {

  // CHECK AUTH STATUS FROM AUTH CONTEXT 
  const { currentUser } = useAuth();

  // IF USER IS LOGGED IN, REDIRECT TO DASHBOARD ELSE TO SIGN IN PAGE 
  return currentUser ? <Navigate to="/" /> : <SignIn />
}

export default SignInPage