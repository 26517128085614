import React from 'react'
import { FiMapPin } from 'react-icons/fi'
import { BsCalendarDate } from 'react-icons/bs'
import moment from 'moment/moment'
import { Link } from 'react-router-dom'

const LocationsTile = ({ branch }) => {
  return (
    <Link to={`/location/${branch.id}`}>
      <div className="bg-white h-48 rounded-md shadow-md">
        {/* location name, data created, location  */}
        <div className="flex flex-row p-4  items-center">
          <div className="rounded-full h-[60px] w-[60px] bg-blue-400"></div>
          <div className="flex flex-col flex-grow pl-3">
            <h2 className="font-bold">{branch.company}</h2>
            <h3 className="text-sm">{branch.branch}</h3>
          </div>
        </div>
        <h1 className="py-4 px-3 text-xl font-semibold flex flex-row items-center gap-2"> <span><FiMapPin className="text-gray-400" /></span>{branch.location}</h1>
        <h1 className="px-4 font-semibold text-sm text-gray-400 flex flex-row items-center gap-2"> <span><BsCalendarDate className="text-gray-400" /></span> {moment(branch.dateAdded.toDate()).format(
          "MMMM Do YYYY, h:mm:ss a"
        )} </h1>
      </div>
    </Link>

  )
}

export default LocationsTile