export const checkinsColumns = [
    {
        field: 'firstName',
        headerName: 'First Name',
        flex: 1,
        cellClassName: "cell-wrap-text"
    },
    {
        field: 'lastName',
        headerName: 'Lst Name',
        flex: 1,
        cellClassName: "cell-wrap-text"
    },
    {
        field: 'phone',
        headerName: 'Phone Number',
        flex: 1,
        cellClassName: "cell-wrap-text"
    },
    {
        field: 'timeIn',
        headerName: 'Time In',
        flex: 1,
        cellClassName: "cell-wrap-text"
    },
    {
        field: 'timeOut',
        headerName: 'Time Out',
        flex: 1,
        cellClassName: "cell-wrap-text"
    },
];