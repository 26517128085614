import React, { useEffect, useRef, useState } from "react";
import QRCodeStyling from 'qr-code-styling'

const LocationQrCode = () => {

    const [url, setUrl] = useState("https://app.timetrac.co");
    const ref = useRef(null);

    
    useEffect(() => {
        qrCode.append(ref.current);
      }, []);
    
      useEffect(() => {
        qrCode.update({
          data: url
        });
      }, [url]);

      
    const qrCode = new QRCodeStyling({
        width: 300,
        height: 300,
        type: "svg",
        data: "https://timetrac.co/",
        image: "/images/logos/blackicon.png",
        dotsOptions: {
            color: "#000000",
            type: "rounded"
        },
        backgroundOptions: {
            color: "#FAFAFA",
        },
        imageOptions: {
            crossOrigin: "anonymous",
            margin: 20
        }
    });

  return (
    <div className='bg-white w-1/2 mt-10 text-center shadow-sm'>
        <div className="h-16 bg-gray-200 flex items-center justify-center font-bold text-xl">Acuvera Systems Limited</div>
        <div className="h-96 grid place-items-center ">
        <div className="p-8" ref={ref} />
        </div>
        <div className="h-16 bg-gray-200 flex items-center justify-center overflow-hidden">
            Powered by - <img src="/images/logos/black.png" alt="" className='w-1/5' />
        </div>
    </div>
  )
}

export default LocationQrCode