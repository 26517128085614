import React, { createContext, useContext } from "react";
import { db } from "../config/firebase";
import { useState } from "react";

import {
    collection,
    // getDocs,
    query,
    orderBy,
    onSnapshot,
    doc,
    where,
    addDoc,
    // updateDoc,
    // getDocs,
    setDoc,
    documentId,
} from "firebase/firestore";


//   COLLECTION REFERENCES 
const usersCollection = collection(db, "users");
const branchCollection = collection(db, "branches");
// const brancRolesCollection = collection(db, 'branch_roles');
const companyCollection = collection(db, 'companies');
const userRolesCollection = collection(db, 'user_roles');
const employeesCollection = collection(db, 'employees');


export const FirestoreContext = createContext();

export const FirestoreContextProvider = ({ children }) => {
    // ******************************CREATE STATE************************************************* 
    // const [companies, setCompanies] = useState([]);
    const [branches, setBranches] = useState([]);
    const [userBranches, setUserBranches] = useState([]);
    const [userData, setUserData] = useState({});
    const [userRoles, setUserRoles] = useState([]);
    const [employees, setEmployees] = useState([]);
    // ******************************END OF CREATE STATE************************************************* 


    // ***************************CREATE DOCUMENTS ***************************
    // CREATE USER DATA
    const createUserData = async (docId, data) => {
        const userRef = doc(usersCollection, docId);
        return await setDoc(userRef, {
            ...data
        }).then(() => {
            console.log('Data updated')
        }).catch((err) => {
            console.log(err.message);
        })
    }

    // CREATE BRANCH DATA
    const createBranch = async (data) => {
        //ADD DOC 
        return await addDoc(branchCollection, {
            ...data
        }).then(async (docRef) => {
            const branchId = docRef.id;
            const userRoles = {
                userId: data.createdBy,
                entityId: branchId,
                createdOn: new Date(),
                role: 'branchAdmin'
            }
            console.log('Data updated');
            // UPDATE THE DOCUMENT WITH THE DOCUMENT ID
            try {
                await setDoc(docRef, {
                    branchId: branchId
                }, { merge: true });
                console.log('Branch created');

                // CREATE USER ROLES 
                createuserRoles(userRoles);
            } catch (err) {
                console.log(err);
            }
        }
        ).catch((err) => {
            console.log(err.message);
        });
    }

    // CREATE COMPANY 
    const createCompany = async (uid, data) => {
        // ADD DOCUMENT THEN UPDATE THE DOCUMENT WITH THE DOCUMENT ID 
        return await addDoc(companyCollection, {
            ...data
        }).then(async (docRef) => {
            const companyId = docRef.id;
            const userRoles = {
                userId: uid,
                entityId: companyId,
                createdOn: new Date(),
                role: 'companyAdmin'
            }
            // UPDATE THE DOCUMENT WITH THE DOCUMENT ID
            try {
                await setDoc(docRef, {
                    companyId: companyId
                }, { merge: true });
                console.log('Company created');

                // CREATE USER ROLES
                createuserRoles(userRoles);
            } catch (err) {
                console.log(err);
            }
        }).catch((err) => {
            console.log(err);
        })
    };

    const createuserRoles = async (data) => {
        return await addDoc(userRolesCollection, {
            ...data
        }).then(() => {
            console.log('User role added');
        }).catch((err) => {
            console.log(err.message);
        })
    }

    // CREATE EMPLOYEE 
    const createEmployee = async (data) => {
        return await addDoc(employeesCollection, {
            ...data
        }).then(() => {
            console.log('Employee added');
        }).catch((err) => {
            console.log(err.message);
        })
    }

    // ***************************END OF CREATE DOCUMENTS ***************************


    // ***************************GET DOCUMENTS ***************************
    // GET BRANCHES ON SNAPSHOT 
    const getBranchesOnSnapshot = () => {
        const q = query(branchCollection, orderBy("dateAdded", "desc"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const branches = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setBranches(branches);
        });
        return unsubscribe;
    }

    // GET USER DATA 
    const getUserOnSnapshot = (uid) => {
        const unsubscribe = onSnapshot(doc(usersCollection, uid), (doc) => {
            const userInfo = doc.data();
            setUserData(userInfo);
            return unsubscribe;
        });
    }

    // GET USER ROLES
    const getUserRolesOnSnapshot = async (uid) => {
        const q = query(userRolesCollection, where("userId", "==", uid));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const roles = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setUserRoles(roles);
        });
        return unsubscribe;
    }

    // GET BRANCHES IF DOCUMENT ID IS EQUAL TO BRANCH ID 
    const getUserBranchesOnSnapshot = async (branchId) => {
        const q = query(branchCollection, where(documentId(), 'in', branchId));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const branches = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setUserBranches(branches);
        });
        return unsubscribe;
    }

    // GET EMPLOYEES
    const getEmployeesOnSnapshot = async (branchId) => {
        const q = query(employeesCollection, where("branchId", "==", branchId));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const employees = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setEmployees(employees);
        });
        return unsubscribe;
    }



    // ***************************END OF GET DOCUMENTS ***************************


    return (
        <FirestoreContext.Provider
            value={{

                // CREATORS
                createUserData,
                createBranch,
                createCompany,
                createuserRoles,
                createEmployee,

                // GETTERS 
                getBranchesOnSnapshot,
                getUserOnSnapshot,
                getUserRolesOnSnapshot,
                getUserBranchesOnSnapshot,
                getEmployeesOnSnapshot,

                // STATES
                branches,
                userData,
                userRoles,
                userBranches,
                employees,

            }}>
            {children}
        </FirestoreContext.Provider>
    )
}

export const DatabaseService = () => {
    return useContext(FirestoreContext)
}