import React from 'react';
import { useAuth } from '../../context/AuthContext';
import { Navigate } from 'react-router-dom';
import SignUp from '../../components/auth/SignUp';

const Register = () => {

  // CHECK AUTH STATUS FROM AUTH CONTEXT
  const { currentUser } = useAuth();

  // IF USER IS LOGGED IN, REDIRECT TO DASHBOARD ELSE TO SIGN UP PAGE
  return currentUser ? <Navigate to="/" /> : <SignUp />
}

export default Register