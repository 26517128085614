import React from 'react'
import CheckinsGrid from './grids/CheckinsGrid'

const Checkins = () => {
  return (
    <div className='bg-white h-screen w-full text-center'>
      <CheckinsGrid />
    </div>
  )
}

export default Checkins