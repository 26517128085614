import React from 'react'
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";

import { BsBuildingAdd } from "react-icons/bs";
import { employeesColumns } from "../../../columns/employeesColumns";

const EmployeesGrid = ({ employees, addEmployee }) => {

  function AddNew() {
    return (
      <div
        onClick={() => addEmployee(true)}
        className="font-light flex flex-row justify-center items-center gap-1 cursor-pointer hover:text-sky-400"
      >
        <BsBuildingAdd className="" />
        ADD NEW
      </div>
    );
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        <AddNew />
      </GridToolbarContainer>
    );
  }


  return (
    <DataGrid
      rows={employees}
      columns={employeesColumns}
      pageSize={5}
      checkboxSelection
      slots={{ toolbar: CustomToolbar }}
    />
  )
}

export default EmployeesGrid