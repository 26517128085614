import React from 'react'
import LocationQrCode from './qr/LocationQrCode'

const LocationQr = () => {
  return (
    <div className='bg min-h-screen flex flex-col items-center pt-8'>
      <h3 className='font-bold text-xl'>Print your QR Code and place at the entrance</h3>
      <LocationQrCode />
    </div>
  )
}

export default LocationQr