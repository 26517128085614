import React, { useEffect, useState } from 'react'
import Navbar from '../layout/Navbar';
import { Routes, Route } from 'react-router-dom';
import LocationsPage from '../../pages/locations/LocationsPage';
import LocationPage from '../../pages/locations/LocationPage';
import { useAuth } from '../../context/AuthContext';
import { DatabaseService } from '../../context/DatabaseContext';

const HomePage = () => {

  // CREATE STATE 
  const [company, setCompany] = useState({});
  const [companyAdmin, setCompanyAdmin] = useState(false);

  // GET USER FROM AUTH 
  const { currentUser } = useAuth();
  const uid = currentUser.uid;

  // GET USER ROLES FROM DATABASE CONTEXT 
  const { userRoles, getUserRolesOnSnapshot } = DatabaseService();

  // FETCH USER ROLES 
  const fetchRoles = async () => {
    try {
      await getUserRolesOnSnapshot(uid);
    } catch (err) {
      console.log(err.message);
    }
  }

  // USE EFFECT
  useEffect(() => {
    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    // CHECK IF USER IS COMPANY ADMIN
    function isCompanyAdmin() {
      return userRoles.some(function (arr) {
        return arr.role === 'companyAdmin'
      })
    }

    // IF USER IS COMPANY ADMIN, GET THE COMPANY 
    if (isCompanyAdmin()) {
      const companyData = userRoles.find(role => role.role === 'companyAdmin');
      setCompany(companyData);
      setCompanyAdmin(true);
    }else{
      console.log('Not company admin');
    }
  }, [userRoles]);

  // FILTER ROLES TO REMOVE COMPANIES 
  const filteredLocations = userRoles.filter(item => item.role !== 'companyAdmin');

  // MAP OVER FILTERED ROLES TO GET LOCATION IDS
  var arrayofLocations = filteredLocations.map(function(location){
    return location['entityId']
  })


  return (
    <div className="app">
      <Navbar />
      <Routes>
        <Route exact path='/' element={<LocationsPage locationIds={arrayofLocations} company={company} companyAdmin={companyAdmin} />} />
        <Route path='/locations' element={<LocationsPage locationIds={arrayofLocations} company={company} companyAdmin={companyAdmin} />} />
        <Route path='/location/:id*' element={<LocationPage />} />
      </Routes>
    </div>
  )
}

export default HomePage;