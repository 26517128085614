import React from 'react'
import Home from '../../components/home/Home'

const HomePage = () => {
  return (
    <div>
        <Home />
    </div>
  )
}

export default HomePage