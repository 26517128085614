import { TextInput } from 'flowbite-react'
import React from 'react';
import { BsPersonPlus } from 'react-icons/bs';

const TopBar = ({ addLocation }) => {
    return (
        <div className=' w-full flex flex-row gap-3'>
            <TextInput color='white' className='flex flex-grow bg-white' placeholder='Search'></TextInput>
            <button onClick={addLocation} className='rounded-lg bg-black text-white px-4'>New Location</button>
            <button
                className='bg-white shadow-sm px-4 rounded-md'>
                <BsPersonPlus />
            </button>
        </div>
    )
}

export default TopBar