import React, { useEffect, useState } from "react";
import TopBar from "../../components/home/toparea/TopBar";
import LocationsTile from "../../components/tiles/LocationsTile";
import { Modal } from "flowbite-react";
import CreateLocation from "../../components/forms/CreateLocation";
import { DatabaseService } from "../../context/DatabaseContext";
import { useAuth } from "../../context/AuthContext";

const LocationsPage = ({ company, companyAdmin, locationIds }) => {
  const [showModal, setShowModal] = useState(false);
  const {
    getBranchesOnSnapshot,
    branches,
    // getUserRolesOnSnapshot,
    userRoles,
    getUserBranchesOnSnapshot,
    userBranches,
  } = DatabaseService();
  const { currentUser } = useAuth();
  const uid = currentUser.uid;

  // FETCH BRANCHES
  const fetchBranches = async () => {
    try {
      await getBranchesOnSnapshot();
    } catch (err) {
      console.log(err);
    }
  };

  //FETCH SPECIFIC BRANCHES
  const fetchuserBranches = async () => {
    try {
      await getUserBranchesOnSnapshot(locationIds);
    } catch (err) {
      console.log(err);
    }
  };

  // WAIT UNTIL LOCATIONIDS IS NOT EMPTY TO FETCH BRANCHES
  // useEffect(() => {
  //   if (locationIds.length > 0) {
  //     fetchuserBranches();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    fetchBranches();
    fetchuserBranches()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   fetchuserBranches();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [locationIds])


  useEffect(() => {}, [branches, userRoles, userBranches, locationIds]);

  const addLocation = () => {
    if (companyAdmin) {
      setShowModal(true);
    } else {
      alert("You don't have permission to add a location");
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };



  return (
    <div className="w-full min-h-screen bg-[#fafafa] pt-20 flex justify-center">
      <div className="w-[95%] lg:w-[80%]">
        <TopBar addLocation={addLocation} />
        <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3 py-8">
          {branches &&
            branches.map((branch) => (
              <LocationsTile key={branch.id} branch={branch} />
            ))}
        </div>
      </div>

      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <Modal.Header>ADD A LOCATION OR A BRANCH</Modal.Header>
        <Modal.Body>
          <CreateLocation closeModal={closeModal} user={uid} companyData={company} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LocationsPage;
