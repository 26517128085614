import React, { useEffect } from 'react'
import { Avatar, Dropdown, Navbar } from 'flowbite-react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext';
import { DatabaseService } from '../../context/DatabaseContext';

const NavbarTop = () => {


  const { signout, currentUser } = useAuth();
  const { getUserOnSnapshot, userData } = DatabaseService();
  const uid = currentUser.uid;

  // FETCH USER 
  const fetchUserData = async () => {
    try {
      await getUserOnSnapshot(uid);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [userData])


  const handleLogout = async () => {
    try {
      await signout();
      console.log('Signed Out')
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <Navbar
      fluid
      rounded
      className='bg-secondary shadow-md fixed w-full z-10 print:hidden'
    // class='bg-green-400 py-3'
    >
      <Navbar.Brand href="/">
        <img
          alt="Flowbite React Logo"
          className="mr-3 h-6 sm:h-9 ml-5"
          src="/images/logos/black.png"
        />
      </Navbar.Brand>
      <div className="flex md:order-2">
        <Dropdown
          inline
          label={<Avatar alt="User settings" img="https://flowbite.com/docs/images/people/profile-picture-5.jpg" rounded />}
        >
          <Dropdown.Header>
            <span className="block text-sm">
              {userData?.firstName + " " + userData?.lastName}
            </span>
            <span className="block truncate text-sm font-medium">
              { userData?.email }
            </span>
          </Dropdown.Header>
          <ul className=' flex flex-col gap-2 px-2'>
            <li>
              <Link className=' ' to='/' >
                Profile
              </Link>
            </li>
            <li>
              <Link className='' to='/' >
                Settings
              </Link>
            </li>
          </ul>

          <Dropdown.Divider />
          <h2 onClick={handleLogout} className='text-red-500 font-semibold px-2 cursor-pointer'>
            Sign out
          </h2>
        </Dropdown>
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        {/* <Navbar.Link
          active
          href="#"
        >
          <p>
            Home
          </p>
        </Navbar.Link>
        <Navbar.Link href="#">
          About
        </Navbar.Link>
        <Navbar.Link href="#">
          Services
        </Navbar.Link>
        <Navbar.Link href="#">
          Pricing
        </Navbar.Link>
        <Navbar.Link href="#">
          Contact
        </Navbar.Link> */}
      </Navbar.Collapse>
    </Navbar>
  )
}

export default NavbarTop