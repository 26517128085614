import { Alert, Card, Spinner, TextInput } from "flowbite-react";
import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import { BsCheck2Circle } from "react-icons/bs";

const Recover = () => {
  // CREATE EMAIL AND PASSWORD STATES
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // CREATE AUTH CONTEXT
  const { recoverPassword } = useAuth();

  const handleSignin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await recoverPassword(email).then(() => {
        setLoading(false);
        setSuccess(true);
      });
      console.log("Recover Password");
    } catch (err) {
      console.log(err);
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen w-full bg-gray-100 grid place-items-center">
      <Card className="shadow-sm bg-white w-5/6 md:w-1/3 ">
        {loading ? (
          <div className="h-[200px] w-full  flex flex-col justify-center items-center">
            <Spinner className="  " />
            <h3 className="text-gray-400">Loading...</h3>
          </div>
        ) : (
          <>
            {success ? (
              <div className="h-[200px] w-full  flex flex-col justify-center items-center">
                <BsCheck2Circle className="text-5xl text-green-500" />
                <h3 className="text-gray-400 text-xl">Successfully reset password</h3>
                <Link to='/signin' className="bg-blue-500 text-white  w-full text-center
                mt-3 py-2 rounded-md">Go to sign in</Link>
              </div>
            ) : (
              <form onSubmit={handleSignin} className="flex flex-col gap-3">
                <div className="grid place-items-center py-4">
                  <img src="/images/logos/black.png" alt="" className="w-1/3" />
                </div>
                <h3 className="font-bold text-2xl py-2">Recover Password</h3>
                <TextInput
                  label="Email"
                  placeholder="Email"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />

                {error !== "" ? (
                  <Alert
                    color={"failure"}
                    variant="success"
                    className="text-center"
                  >
                    {error}
                  </Alert>
                ) : (
                  ""
                )}

                <button
                  className="bg-blue-500 rounded-md p-2 font-semibold text-white"
                  type="submit"
                  disabled={loading}
                >
                  Recover
                </button>

                <div className="flex flex-row justify-between">
                  <Link to="/signup" className="font-semibold">
                    Register?
                  </Link>
                  <Link to="/signin" className="font-semibold italic">
                    Sign In?
                  </Link>
                </div>
              </form>
            )}
          </>
        )}
      </Card>
    </div>
  );
};

export default Recover;
