
export const employeesColumns = [
    {
        field: 'firstName', 
        headerName: 'First Name',
        flex: 1,
        cellClassName: "cell-wrap-text"
    }, 
    {
        field: 'lastName',
        headerName: 'Last Name',
        flex: 1,
        cellClassName: "cell-wrap-text"
    }, 
    {
        field: 'email',
        headerName: 'Email',
        flex: 2,
        cellClassName: "cell-wrap-text"
    },
    {
        field: 'phone',
        headerName: 'Phone',
        flex: 1,
        cellClassName: "cell-wrap-text"
    },
    {
        field: 'idNumber',
        headerName: 'ID Number',
        flex: 1,
        cellClassName: "cell-wrap-text"
    }
];