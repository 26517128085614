import React from "react";
import LocationSideBar from "../../components/location/LocationSideBar";
import Employees from "../../components/location/Employees";
import { Route, Routes } from "react-router-dom";
import Checkins from "../../components/location/Checkins";
import LocationQr from "../../components/location/LocationQr";

const LocationPage = () => {
  return (
    <div className="min-h-screen bg-[#fafafa] pt-20 flex flex-row">
      <div className="bg-red w-1/5 relative p-2">
        <LocationSideBar />
      </div>
      <div className=" grow h-[200vh] p-2 py-4">
        <div className=" bg-white rounded-lg  shadow-sm h-screen">
          <Routes>
            <Route path="employees" element={<Employees />} />
            <Route path="checkins" element={<Checkins /> } />
            <Route path="printqr" element={<LocationQr />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default LocationPage;
