import React, { useState } from 'react'
import { Spinner } from 'flowbite-react';
import { BsCheck } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import { DatabaseService } from '../../context/DatabaseContext';


const CreateEmployee = ({ closeModal }) => {

    const { createEmployee } = DatabaseService();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    // const [companyId, setCompanyId] = useState('');

    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const { id } = useParams();

    const handleSubmit = (e) => {
        e.preventDefault();
        const employeeData = {
            firstName: firstName,
            lastName: lastName,
            idNumber: idNumber,
            email: email,
            phone: phone,
            branchId: id,
            // companyId: companyId,
            dateAdded: new Date()
        }

        try {
            setLoading(true);
            createEmployee(employeeData).then(() => {
                setLoading(false);
                setSuccess(true);
                setTimeout(() => {
                    closeModal();
                    setSuccess(false)
                }, 2000)
            })

        } catch (err) {
            setLoading(false);
            setError(err.message);
        }
    }



    return (
        <>
            {
                loading ? <div className=" w-full grid place-items-center py-8"><Spinner /></div>
                    :
                    <>
                        {
                            success ? <div className="w-full grid place-items-center py-8">
                                <BsCheck className='text-green-500 text-4xl' />
                                <p className="text-green-500">Location added successfully</p>
                            </div>
                                :
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="firstName">
                                            First Name
                                        </label>
                                        <input onChange={(e) => setFirstName(e.target.value)} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="firstName" type="text" placeholder="First Name" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastName">
                                            Last Name
                                        </label>
                                        <input onChange={(e) => setLastName(e.target.value)} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="lastName" type="text" placeholder="Last Name" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                            Email
                                        </label>
                                        <input onChange={(e) => setEmail(e.target.value)} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Email" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
                                            Phone
                                        </label>
                                        <input onChange={(e) => setPhone(e.target.value)} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="phone" type="text" placeholder="Phone" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="location">
                                            ID Number
                                        </label>
                                        <input onChange={(e) => setIdNumber(e.target.value)} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="idNumber" type="text" placeholder="ID Number" />
                                    </div>

                                    {error && <h3 className='mb-4 text-center text-red-600 font-semibold'>{error}</h3>}
                                    <div className="mb-4">
                                        <button className='bg-black text-white w-full py-3 rounded-md' type='submit'>SUBMIT</button>
                                    </div>
                                </form>
                        }
                    </>

            }

        </>
    )
}

export default CreateEmployee