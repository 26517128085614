import { Alert, Card, Spinner, TextInput } from "flowbite-react";
import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { Link } from "react-router-dom";

const SignUp = () => {
  // CREATE EMAIL AND PASSWORD STATES
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [confirmPassword, setConfirmPassowrd] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");

  // CREATE AUTH CONTEXT
  const { signup } = useAuth();

  const handleSignup = async (e) => {
    e.preventDefault();
    setLoading(true);

    const userData = {
      firstName: firstName,
      lastName: lastName,
      company: company,
      email: email,
      registeredOn: new Date()
    }
    try {
      await signup(email, password, userData);
      console.log("Signed Up");
    } catch (err) {
      console.log(err);
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen w-full bg-gray-100 grid place-items-center">
      <Card className="shadow-sm bg-white w-5/6 md:w-1/3 ">
        {loading ? (
          <div className="h-[200px] w-full  flex flex-col justify-center items-center">
            <Spinner className="  " />
            <h3 className="text-gray-400">Loading...</h3>
          </div>
        ) : (
          <form onSubmit={handleSignup} className="flex flex-col gap-3">
            <div className="grid place-items-center py-4">
              <img src="/images/logos/black.png" alt="" className="w-1/3" />
            </div>
            <h3 className="font-bold text-2xl py-2">Register</h3>

            <div className="flex md:flex-row flex-col gap-3 md:gap-1">
              <TextInput
              className="md:w-1/2 w-full"
                label="First Name"
                required
                placeholder="First Name..."
                type="text"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
              <TextInput
              className="md:w-1/2 w-full"
                label="Last Name"
                required
                placeholder="Last Name..."
                type="text"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
            </div>
            <TextInput
              label="Company"
              required
              placeholder="Company..."
              type="text"
              onChange={(e) => setCompany(e.target.value)}
              value={company}
            />
            <TextInput
              label="Email"
              required
              placeholder="Email"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <TextInput
              label="Password"
              required
              placeholder="Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />

            {error !== "" ? (
              <Alert
                color={"failure"}
                variant="success"
                className="text-center"
              >
                {error}
              </Alert>
            ) : (
              ""
            )}

            <button
              className="bg-blue-500 rounded-md p-2 font-semibold text-white"
              type="submit"
              disabled={loading}
            >
              Sign Up
            </button>

            <div className="flex flex-row justify-between">
              <Link to="/signin" className="font-semibold">
                Sign in?
              </Link>
              <Link to="/recover" className="font-semibold italic">
                Forgot Password?
              </Link>
            </div>
          </form>
        )}
      </Card>
    </div>
  );
};

export default SignUp;
