import React, { useEffect, useState } from "react";
import EmployeesGrid from "./grids/EmployeesGrid";
import { DatabaseService } from "../../context/DatabaseContext";
import { useParams } from "react-router-dom";
import { Modal } from "flowbite-react";
import CreateEmployee from "../forms/CreateEmployee";




const Employees = () => {

  const [showModal, setShowModal] = useState(false);

  const { employees, getEmployeesOnSnapshot } = DatabaseService();

  // GET PAGE ID 
  const { id } = useParams()

  // FETCH EMPLOYEES 
  const fetchEmployees = async () => {
    try {
      await getEmployeesOnSnapshot(id);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    console.log(employees);
  }, [employees]);

  const addEmployee = () => {
    setShowModal(true);
  }

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <div className="bg-white h-screen w-full text-center">
      <EmployeesGrid employees={employees} addEmployee={addEmployee} />

      {/* FLOWBITE MODAL  */}
      <Modal show={showModal} onClose={(() => setShowModal(false))} >
        <Modal.Header>Add an employee</Modal.Header>
        <Modal.Body>
          <CreateEmployee closeModal={closeModal} />
        </Modal.Body>

      </Modal>
      
    </div>
  );
};

export default Employees;
