import React from 'react'
import { BsPersonPlus, BsPrinter, BsCalendar2Check } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { AiOutlineDelete } from "react-icons/ai";

const LocationSideBar = () => {

  const { id } = useParams();
  return (
    <div className=" w-full py-2 print:hidden">
      <div className="">
        <div className="flex flex-row rounded-md bg-white p-3 gap-2 items-center shadow-sm">
          <div className="w-1/3">
            <div className="rounded-full w-16 h-16 bg-green-100 grid place-items-center">
              <h3 className="text-xl font-bold ">A.D</h3>
            </div>
          </div>
          <ul className="grow">
            <li className="text-sm line font-bold leading-4">
              Acuvera Systems Limited{" "}
            </li>
            <li className="text-gray-500 font-semibold text-sm">
              Branch Name
            </li>
          </ul>
        </div>
        <div className="mt-2 flex flex-col gap-[2px]">
          <Link
            to={`/location/${id}/employees`}
            // to="/location/{$}/employees"
            className="py-3 px-2 bg-gray-100 hover:bg-gray-200 cursor-pointer flex flex-row items-center gap-2 rounded-md"
          >
            <span>
              <BsPersonPlus className="text-xl" />
            </span>{" "}
            Manage Employees
          </Link>


          <Link
            to={`/location/${id}/printqr`}
            className="py-3 px-2 bg-gray-100 hover:bg-gray-200 cursor-pointer flex flex-row items-center gap-2 rounded-md"
          >
            <span>
              <BsPrinter className="text-xl" />
            </span>{" "}
            Print QR
          </Link>


          <Link
            to={`/location/${id}/checkins`}
            className="py-3 px-2 bg-gray-100 hover:bg-gray-200 cursor-pointer flex flex-row items-center gap-2 rounded-md"
          >
            <span>
              <BsCalendar2Check className="text-xl" />
            </span>{" "}
            Checkins
          </Link>
          <Link
            to="/dsd"
            className="py-3 px-2 bg-gray-100 hover:bg-gray-200 cursor-pointer flex flex-row items-center gap-2 rounded-md"
          >
            <span>
              <IoIosRemoveCircleOutline className="text-xl" />
            </span>{" "}
            Suspend Branch
          </Link>
          <Link
            to="/dsd"
            className="py-3 px-2 bg-gray-100 hover:bg-gray-200 cursor-pointer flex flex-row items-center gap-2 rounded-md"
          >
            <span>
              <AiOutlineDelete className="text-xl" />
            </span>{" "}
            Delete Branch
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LocationSideBar