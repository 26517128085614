import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../config/firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { DatabaseService } from './DatabaseContext';

// CREATE CONTEXT 
const AuthContext = createContext();

// EXPORT USE AUTH FUNCTION
export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {

    const { createUserData, createCompany } = DatabaseService();



    // CREATE CURRENT USER
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);

    // **********************CREATE THE VARIOUS AUTH FUNCTIONS********************** 

    // CREATE USER 
    const signup = async (email, password, userData) => {
        return await createUserWithEmailAndPassword(auth, email, password).then((cred) => {
            const uid = cred.user.uid;
            // COMPANY DATA 
            const company = {
                companyName: userData.company,
                createdOn: new Date(), 
                createdBy: uid
            }
            // CREATE USER DATA 
            createUserData(uid, userData).then(() => {
                // CREATE COMPANY DATA AND ADD USER ROLES 
                createCompany(uid, company).then(() => {
                    console.log('Company and user roles added');
                }).catch((err) => {
                    console.log(err.message)
                })
            }).catch((err) => {
                console.log(err.message)
            })




        }).catch((err) => {
            console.log(err.message);
        })
    }

    // SIGN IN USER 
    function signin(email, password) {
        try {
            return signInWithEmailAndPassword(auth, email, password);
        } catch (err) {
            console.log(err);
        }
    }

    // SIGN OUT USER 
    function signout() {
        return auth.signOut();
    }

    // RECOVER PASSWORD 
    const recoverPassword = async (email) => {
        return await sendPasswordResetEmail(auth, email).then(() => {
            console.log("Email sent");
        }).catch((err) => {
            console.log(err);
        })
    }

    // ONSTATE CHANGED 
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
            setLoading(false);
        });
        return unsubscribe; // unsubscribe on unmount
    }, []);

    // **********************CREATE THE VARIOUS AUTH FUNCTIONS********************** 



    // CREATE VALUE
    const value = {
        currentUser,
        signup,
        signin,
        signout,
        loading,
        recoverPassword
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
