import React from 'react'
import {
    DataGrid,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
  } from "@mui/x-data-grid";
import { checkinsColumns } from '../../../columns/checkinsColumns';

const CheckinsGrid = () => {
    const checkins = [
        { id: 1, firstName: 'Snow', lastName: 'Jon', phone: '1234567890', timeIn: '12:00', timeOut: '12:00' },
        { id: 2, firstName: 'Lannister', lastName: 'Cersei', phone: '1234567890', timeIn: '12:00', timeOut: '12:00' },
    ]

    
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  return (
    <DataGrid
    rows={checkins}
    columns={checkinsColumns}
    pageSize={5}
    checkboxSelection
    slots={{ toolbar: CustomToolbar }}
  />
  )
}

export default CheckinsGrid